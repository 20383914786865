import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import SEO from "@components/SEO";
import Layout from "@components/Layout";

export default function NotFound() {
    console.log("test log")

    return (
        <Layout>
            <div className="vh-100">
                <SEO title="Oops! Halaman yang kamu cari tidak ditemukan." />
                <Container fluid className="h-100 py-5">
                    <Row className="h-100 justify-content-center align-items-center">
                        <Col className="mb-4">
                            <Row className="justify-content-center align-items-end text-center">
                                <Col md={12}>
                                    <h2>
                                        404 : Halaman yang kamu cari tidak
                                        ditemukan.
                                    </h2>
                                    <Link to="/">Kembali</Link>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
}
